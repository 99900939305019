import React from "react";
import theme from "theme";
import { Theme, Text, Icon, Link, Box, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { BsEnvelope, BsClock, BsMap } from "react-icons/bs";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contacts"} />
		<Helmet>
			<title>
				Temiz Hizmet
			</title>
			<meta name={"description"} content={"Özel ihtiyaçlarınıza göre uyarlanmış birinci sınıf ev temizlik hizmetleri sunarak burada devreye giriyoruz. "} />
			<meta property={"og:title"} content={"Temiz Hizmet"} />
			<meta property={"og:description"} content={"Özel ihtiyaçlarınıza göre uyarlanmış birinci sınıf ev temizlik hizmetleri sunarak burada devreye giriyoruz. "} />
			<meta property={"og:image"} content={"https://images.unsplash.com/photo-1628177142898-93e36e4e3a50?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
			<link rel={"shortcut icon"} href={"https://images.unsplash.com/photo-1628177142898-93e36e4e3a50?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://images.unsplash.com/photo-1628177142898-93e36e4e3a50?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://images.unsplash.com/photo-1628177142898-93e36e4e3a50?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://images.unsplash.com/photo-1628177142898-93e36e4e3a50?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
			<link rel={"apple-touch-startup-image"} href={"https://images.unsplash.com/photo-1628177142898-93e36e4e3a50?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
			<meta name={"msapplication-TileImage"} content={"https://images.unsplash.com/photo-1628177142898-93e36e4e3a50?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
		</Helmet>
		<Components.Header>
			<Override slot="link3" />
		</Components.Header>
		<Section padding="80px 0 0px 0" quarkly-title="Contacts-5">
			<Override slot="SectionContent" align-items="center" />
			<Text
				lg-padding="0px 0px 0px 0px"
				sm-font="normal 700 36px/1.2 --fontFamily-sans"
				margin="0px 0px 18px 0px"
				font="normal 600 42px/1.2 --fontFamily-sans"
				color="--red"
				text-align="center"
				padding="0px 0 0px 0px"
				lg-width="100%"
				lg-margin="0px 0px 25px 0px"
			>
				{" "}Numarayı ara +902123699075
			</Text>
			<Text
				font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
				lg-margin="0px 0 50px 0px"
				text-align="center"
				sm-margin="0px 0 35px 0px"
				margin="16px 0 50px 0px"
				color="--dark"
				width="700px"
				md-width="auto"
			>
				Sizi Temiz Hizmet Hizmetleri ailesinin bir parçası olmaya davet ediyoruz. Evinizin temizliğini bize bırakın, böylece sizin için en önemli olana odaklanabilirsiniz. Ekibimiz yaşam alanınızı tertemiz ve konforun bozulmamış bir cennetine dönüştürmeye hazır. Temiz Hizmet Hizmetlerini seçin ve gerçekten temiz bir evin keyfini keşfedin.
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="16px 24px"
				margin="0px 0px 80px 0px"
				md-margin="0px 0px 50px 0px"
				lg-grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="1fr"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					padding="36px 20px 20px 20px"
					border-width="1px"
					border-style="solid"
					border-color="--color-lightD2"
					border-radius="4px"
				>
					<Icon
						category="bs"
						icon={BsEnvelope}
						size="36px"
						margin="0px 0px 20px 0px"
						color="--red"
					/>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 24px/1.2 --fontFamily-sansHelvetica"
						text-align="center"
						color="--red"
						md-margin="0px 0px 20px 0px"
					>
						Posta
					</Text>
					<Link
						href="mailto:vortexinestar@gmail.com"
						color="--dark"
						text-decoration-line="initial"
						font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
						display="block"
						margin="0px 0px 15px 0px"
						text-align="center"
					>
						info@movixenonstill.com
					</Link>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					padding="36px 20px 20px 20px"
					border-width="1px"
					border-style="solid"
					border-color="--color-lightD2"
					border-radius="4px"
				>
					<Icon
						category="bs"
						icon={BsClock}
						size="36px"
						margin="0px 0px 20px 0px"
						color="--red"
					/>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 24px/1.2 --fontFamily-sansHelvetica"
						text-align="center"
						color="--red"
						md-margin="0px 0px 20px 0px"
					>
						Çalışma saati
					</Text>
					<Text
						margin="0px 0px 15px 0px"
						color="--dark"
						font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
						text-align="center"
						border-color="#b8acac"
					>
						Günlük 10:00 - 21:00
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					padding="36px 20px 20px 20px"
					border-width="1px"
					border-style="solid"
					border-color="--color-lightD2"
					border-radius="4px"
				>
					<Icon
						category="bs"
						icon={BsMap}
						size="36px"
						margin="0px 0px 20px 0px"
						color="--red"
					/>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 24px/1.2 --fontFamily-sansHelvetica"
						text-align="center"
						color="--red"
						md-margin="0px 0px 20px 0px"
					>
						Adres
					</Text>
					<Text
						margin="0px 0px 20px 0px"
						color="--dark"
						font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
						text-align="center"
						border-color="#b8acac"
						md-margin="0px 0px 15px 0px"
					>
						Halıcıoğlu, Kapı Kahyası Sk. 14/A,{" "}
						<br />
						34445 Beyoğlu/İstanbul, Türkiye
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="0 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-8">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="33.333%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
			>
				<Box
					width="100%"
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					padding="0px 0px 140% 0px"
					height="auto"
					position="relative"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						left={0}
						src="https://uploads.quarkly.io/665448d7e8900d0022f2aff2/images/1-4.jpg?v=2024-05-27T08:50:53.453Z"
						object-fit="contain"
						position="absolute"
						display="block"
						width="100%"
						top="auto"
						right={0}
						bottom="0px"
						min-height="100%"
						srcSet="https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/1-4.jpg?v=2024-05-27T08%3A50%3A53.453Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/1-4.jpg?v=2024-05-27T08%3A50%3A53.453Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/1-4.jpg?v=2024-05-27T08%3A50%3A53.453Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/1-4.jpg?v=2024-05-27T08%3A50%3A53.453Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/1-4.jpg?v=2024-05-27T08%3A50%3A53.453Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/1-4.jpg?v=2024-05-27T08%3A50%3A53.453Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/1-4.jpg?v=2024-05-27T08%3A50%3A53.453Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
			<Box
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				width="33.333%"
				lg-width="100%"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-order="1"
				display="flex"
			>
				<Box
					padding="0px 0px 140% 0px"
					width="100%"
					overflow-y="hidden"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					height="auto"
					overflow-x="hidden"
					position="relative"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						width="100%"
						bottom="0px"
						min-height="100%"
						display="block"
						top="auto"
						left={0}
						right={0}
						src="https://uploads.quarkly.io/665448d7e8900d0022f2aff2/images/2-3.jpg?v=2024-05-27T08:50:53.451Z"
						object-fit="contain"
						position="absolute"
						srcSet="https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/2-3.jpg?v=2024-05-27T08%3A50%3A53.451Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/2-3.jpg?v=2024-05-27T08%3A50%3A53.451Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/2-3.jpg?v=2024-05-27T08%3A50%3A53.451Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/2-3.jpg?v=2024-05-27T08%3A50%3A53.451Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/2-3.jpg?v=2024-05-27T08%3A50%3A53.451Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/2-3.jpg?v=2024-05-27T08%3A50%3A53.451Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/2-3.jpg?v=2024-05-27T08%3A50%3A53.451Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
			<Box
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-order="1"
				display="flex"
				width="33.333%"
				lg-width="100%"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				align-items="flex-start"
				lg-padding="0px 16px 16px 16px"
				justify-content="center"
			>
				<Text
					sm-margin="30px 0px 0px 0px"
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Temiz Hizmet'in olağanüstü temizlik hizmetlerini bugün deneyin. İlk temizliğinizi planlamak için bizimle iletişime geçin ve neden bu kadar çok müşterinin evleri konusunda bize güvendiğini öğrenin. Memnuniyetiniz bizim taahhüdümüzdür ve evinizi parlatmak için buradayız.
				</Text>
			</Box>
		</Section>
		<Components.Footer>
			<Override slot="text3">
				Halıcıoğlu, Kapı Kahyası Sk. 14/A,{" "}
				<br />
				34445 Beyoğlu/İstanbul, Türkiye
			</Override>
			<Override slot="link3">
				+902123699075
			</Override>
			<Override slot="link4">
				info@movixenonstill.com
			</Override>
			<Override slot="text" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65b35f058e2e8e002176cca3"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});